body, html{
  padding: 0px;
  margin: 0px;
  overflow-x: hidden !important;
  font-family: 'Roboto',Arial, sans-serif;
  color: #000000;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* a, a:visited, a:focus{
  outline: none;
  border: none;
  text-decoration: none;
  color: #000;
}
a:hover{
  text-decoration: underline;
} */
.react-datepicker-popper{
  z-index: 100;
}
.main-header{
  height: 50px;
  background-color: #b5ff00;
  display: table;
  width: 100%;
}
.main-header .logo-wrapper, .main-header .nav-link, .main-footer .logo-wrapper, .main-footer .nav-link{
  display: table-cell;
  vertical-align: middle;
}
.main-header .logo-wrapper{
  width: 150px;
  text-align: center;
  font-size: 20px;
  font-weight: 800;
}
.main-header .logo-wrapper a{
  font-size: 20px;
}
.main-header .nav-link{
  text-align: right;
}
.nav-link ul {
  margin: 0px;
}
.main-header li, .main-footer li{
  display: inline-block;
  min-width: 100px;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
}
/* .body{
  min-height: calc(100vh - 84px);
} */
.login-page {
  min-height: calc(100vh - 152px);
}
.main-footer{
  padding: 10px;
  text-align: center;
  font-size: 12px;
  background-color: #d9d9d9;
}
.alert-text{
  margin-top: 10px;
}
.main-section-container{
  display: block;
  position: relative;
  white-space: nowrap;
  width: 100% !important;
  padding: 0px !important;
  margin: 0px;
  min-height: calc(100vh - 88px);
  display: table !important;
  table-layout: fixed;
}
.section-left-panel{
  width: 13%;
  max-width: 200px;
  display: table-cell;
  vertical-align: top;
  height: 100%;
  background-color: #dedede;
}
.section-left-panel .side-menu ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.side-menu ul li a {
  padding: 10px;
  display: block;
  background: #dedede;
  border-bottom: 1px solid #c1c1c1;
  color: #000000;
  text-decoration: none;
}
.side-menu ul li:last-child a{
  border-bottom: none;
}
.section-right-panel{
  width: 87%;
  min-width: calc(100% - 200px);
  display: table-cell;
  height: 100%;
  vertical-align: top;
}
.panel-section-container {
  padding-top: 20px;
  padding-bottom: 5px;
  max-height: 700px;
  overflow-y: scroll;
}
.library-grid-item-container{
  white-space: normal;
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, calc(20% - 4px));
  grid-auto-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  width: 100%;
}
.library-grid-item {
  min-height: 180px;
  width: 100%;
  position: relative;
  flex: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  vertical-align: middle;
  border: 1px solid #8b8989;
  background-color: #c5c5c5;
  cursor: pointer;
  text-align: center;
}
.library-grid-item-info {
  /* background-color: #b5ff00; */
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 0px;
  -webkit-transition: height 0.2s ease-out;
  -moz-transition: height 0.2s ease-out;
  -o-transition: height 0.2s ease-out;
  transition: height 0.2s ease-out;
  overflow: hidden;
  text-align: center;
}
.library-grid-item-info button{
  margin: 0px;
  width: 90%;
}
.library-grid-item:hover .library-grid-item-info{
  height: 45px;
}
.library-grid-item audio{
  width: 100%;
  display: block;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator{
  display: none;
}
.artist-country-flag {
  vertical-align: middle;
  margin-left: 7px;
}
.breakspace{
  white-space: normal;
}
.live-container{
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.live-container .live-indicator{
  display: block;
  background-color: #ff0000;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  margin-right: 5px;
  -moz-animation:blink normal 1.5s infinite ease-in-out;
  -webkit-animation:blink normal 1.5s infinite ease-in-out;
  -ms-animation:blink normal 1.5s infinite ease-in-out;
  animation:blink normal 1.5s infinite ease-in-out;
}
@keyframes blink {
  0% {
         background-color: rgba(255,0,0,1)
  }
  50% {
         background-color: rgba(255,0,0,0)
  }
  100% {
         background-color: rgba(255,0,0,1)
  }
}
@-webkit-keyframes blink {
  0% {
         background-color: rgba(255,0,0,1)
  }
  50% {
         background-color: rgba(255,0,0,0)
  }
  100% {
         background-color: rgba(255,0,0,1)
  }
}